import { Controller } from "@hotwired/stimulus"
import { Sortable, Swap } from 'sortablejs/modular/sortable.core.esm';
import { patch } from "@rails/request.js";

Sortable.mount(new Swap)

class SortableV2 extends Controller {
  static values = { url: String };

  connect() {
    this.sortable = Sortable.create(this.element, {
      swap: true,
      swapClass: 'highlight',
      animation: 300,
      onEnd: this.onEnd.bind(this),
      handle: "[data-sortable-handle-value]",
    });
  }

  disconnect() {
    this.sortable.destroy();
  }

  async onEnd(event) {
    const { newIndex, item, swapItem } = event;
    const url = item.dataset["sortableUpdateUrl"]
    const { response } = await patch(url, {
      body: JSON.stringify({
        answer: {
          position: newIndex,
          target_id: parseInt(swapItem.getAttribute("data-id"), 10)
        }
      })
    });

    if (response.status === 200) {
      const { source, target } = await response.json()

      if (source && target) {
        const data = {
          source: {
            ...source,
            item: document.querySelectorAll(`[data-id='${source.id}']`)[0]
          },
          target: {
            ...target,
            item: document.querySelectorAll(`[data-id='${target.id}']`)[0]
          },
        }

        this.updateItemColor(source.correct, data.source.item)
        this.updateItemColor(target.correct, data.target.item)
      }
    }
  }

  updateItemColor(status, item) {
    if (status) {
      item.classList.remove("dnd-item--invalid")
      item.classList.add("dnd-item--valid")
    } else {
      item.classList.remove("dnd-item--valid")
      item.classList.add("dnd-item--invalid")
    }
  }
}

export default SortableV2
