function clickSubmitButton() {
  document.querySelectorAll('.js-click-submit-button').forEach(element => {
    element.addEventListener('click', (event) => {
      event.preventDefault()
      const formAction = element.dataset.formAction

      const form = document.querySelector(`form[action="${formAction}"]`)

      if (form.checkValidity()) {
        form.querySelector('[type="submit"]').click()
      }
      else {
        form.reportValidity()
      }
    })
  })
}

document.addEventListener('turbo:load', clickSubmitButton);
document.addEventListener('turbo:frame-render', clickSubmitButton);

const timeout = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export async function sleep(ms_period = 1000, fn = () => { }) {
  console.log("SLEEP START", new Date())
  await timeout(ms_period);
  console.log("SLEEP FINISH", new Date())
  return fn();
}

async function pressContinueAfterSubmit(event) {
  const submitButton = event.detail.formSubmission.submitter

  const response = await event.detail.formSubmission.result.fetchResponse.response.text()

  // looking for presence of form errors section id
  const valid = response.search("form-errors") == -1

  if (submitButton.classList.contains("js-press-continue") && valid) {
    await sleep()

    document.getElementById("continue")?.click()
  }
}
document.addEventListener('turbo:submit-end', pressContinueAfterSubmit);
