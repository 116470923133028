import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    id: String,
    formInputId: String,
  }

  connect() {
    this.values.formInput = document.getElementById(this.values.formInputId)

    this.values.formInput.addEventListener('change', function(e) {
      var fileInput = e.target

      if (fileInput.files.length > 0) {
        const addedFilesArea = document.getElementById(`added-files-${this.values.id}`)

        const template = document.getElementById('file-item-template')
        const fileItem = document.importNode(template.content, true);

        const list = createListWithTemplate(e.target.files, deleteHandler(addedFilesArea, fileInput))

        if (fileInput.getAttribute("multiple")?.length == 0) {
          cleanList(addedFilesArea)
        }

        addedFilesArea.append(list);
      }
    });
  }

  cleanList(node) {
    node.innerHTML = ""
  }

  cleanFileList(input) {
    input.value = null
  }

  deleteHandler(addedFilesArea, fileInput) {
    return (filename) => {
      if (fileInput.getAttribute("multiple")?.length > 0) {
        return (e) => {
          console.log("delete! ", filename)
          console.log("all files ", fileInput.files)
          const resultDT = new DataTransfer();
          let fileDeleted = false;

          for (const file of fileInput.files) {
            if (file.name !== filename) {
              resultDT.items.add(file);
            } else {
              fileDeleted = true;
            }
          }

          fileInput.files = resultDT.files;

          cleanList(addedFilesArea)
          const list = createListWithTemplate(fileInput.files, deleteHandler(addedFilesArea, fileInput))
          addedFilesArea.append(list);
        }
      } else {
        return (e) => {
          cleanList(addedFilesArea)
          cleanFileList(e.target)
        } // only for a single file scenario
      }
    }
  }

  createListWithTemplate(files, deleteHandler) {
    const ul = document.createElement('ul');
    ul.classList.add('list', 'file-list', "flex-col", "flex", "gap-2", "w-full");

    const template = document.getElementById('file-template');

    Array.from(files).forEach((file) => {
      const fileCard = document.importNode(template.content, true);
      fileCard.querySelector('.name').textContent = file.name;
      fileCard.querySelector(".delete-icon").addEventListener("click", deleteHandler(file.name))
      ul.appendChild(fileCard);
    });

    return ul;
  }

  dropHandler(ev) {
    console.log("File(s) dropped");

    const fileInput = document.getElementById("<%= form_input_id %>")
    const addedFilesArea = document.getElementById(`added-files-${this.values.id}`)

    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    const resultDT = new DataTransfer();

    for (const file of ev.dataTransfer.files) {
      resultDT.items.add(file);
    }

    for (const file of fileInput.files) {
      resultDT.items.add(file);
    }

    fileInput.files = resultDT.files
    console.log("files", fileInput.files)

    cleanList(addedFilesArea)
    const list = createListWithTemplate(fileInput.files, deleteHandler(addedFilesArea, fileInput))
    addedFilesArea.append(list);
  }

  dragOverHandler(ev) {
    console.log("File(s) in drop zone");

    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
  }

  clickHandler(ev) {
    ev.preventDefault();

    const id = "<%= form_input_id %>"

    const fileInput = document.getElementById(id)
    console.log("click handler file input", fileInput)

    fileInput.click()
    console.log("Open file explorer");
  }
}
