import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import Carousel from 'stimulus-carousel'
import SortableV2 from "./sortable_v2_controller"

const context = require.context("./", true, /\.js$/)
const application = Application.start()

application.register('carousel', Carousel)
application.register('sortable', SortableV2)

window.Stimulus = application
Stimulus.load(definitionsFromContext(context))
